<template>
<div class="bg-secondary text-white p-md-5 py-5">
  <div class="container">
    <h4 class="text-center">SKIT IT Solutions</h4>
    <div class="row mt-5">
      <div class="col-6 col-lg-4 order-1 order-md-0 row">
        <div class="col-12">
          <div class="font-weight-bold"><i class="bi bi-person mr-2"></i> Inhaber:</div>
          <span>Sven Krauß</span>
        </div>
        <div class="col-12 col-md-12">
          <div class="font-weight-bold mt-4"><i class="bi bi-envelope mr-2"></i> E-Mail:</div>
          <span>info@skit-it.de</span>
        </div>
        <div class="col-12 col-md-12">
          <div class="font-weight-bold mt-4"><i class="bi bi-telephone mr-2"></i> Telefon:</div>
          <span>09723 90 74 852</span>
        </div>
      </div>

      <div class="col-6 col-lg-5 order-0 order-md-1">
        <strong><i class="bi bi-clock mr-2"></i> Öffnungszeiten:</strong>
        <div class="row">
          <div class="col-12 font-weight-bold mt-2">
            Montag - Freitag:
          </div>
          <div class="col-12">
            15:00 - 20:00 Uhr
          </div>
          <div class="col-12 font-weight-bold mt-2">
            Samstag:
          </div>
          <div class="col-12">
            09:00 - 18:00 Uhr
          </div>
        </div>
        <div class="font-weight-bold mt-4"><i class="bi bi-geo mr-2"></i> Anschrift:</div>
        <span>Am Weinberg 11</span><br>
        <span>97509 Unterspiesheim</span><br>
        <span>Bayern, Germany DE</span>
      </div>
      <div class="col-12 col-lg-3 order-2 oder-md-2 mt-4 mt-lg-0 text-center text-lg-left">
        <div class="mt-4 font-weight-bold">
          <i class="bi bi-file-earmark-medical mr-2"></i> Rechtliches:
        </div>
        <a href="{{ route('imprint') }}">Impressum</a> & Datenschutz
      </div>
    </div>
  </div>
</div>
<!-- {{-- Alle Rechte vorbehalten --}} -->
<div class="text-center text-white bg-primary">
  <small>
    <span class="font-weight-bold">SKIT IT Solution © by Sven Krauß</span>
    <span>Alle Rechte vorbehalten.</span>
  </small>
</div>
</template>