
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Carousel from '@/components/carousel.vue'

@Options({
  components: {
    HelloWorld,
    Carousel,
  },
})
export default class Home extends Vue {}
