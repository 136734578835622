<template> 
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="@/assets/images/project.jpg" class="d-block w-100 image-wrapper" alt="...">
        <div class="carousel-caption bg-secondary">
          <h5>Eine perfekte Planung ist das A und O!</h5>
          <p class="d-none d-md-block">Ihr Projekt wird nach Abstimmung mit Ihnen in einem detailierten Konzept
            zusammengefasst</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/hands-it.jpg" class="d-block w-100 image-wrapper" alt="...">
        <div class="carousel-caption bg-primary">
          <h5>First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p>
        </div>
      </div>
    </div>
    <div class="carousel-control-prev" data-target="#carouselExampleControls" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </div>
    <div class="carousel-control-next" data-target="#carouselExampleControls" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </div>
  </div>
</template>
<script>
  //importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'
//import DemoComponent from "./components/DemoComponent.vue"
 
export default {
  
 mounted(){
    var myCarousel = document.querySelector('#myCarousel')
       new Bootstrap.Carousel(myCarousel)
 }
}
</script>