<template>
  
  <div class="bg-secondary text-white">
  <div class="container">
    <div class="d-flex justify-content-between">
      <!-- {{-- Telefon --}} -->
      <a class="text-decoration-none text-reset" href="tel:+4997239074852">
        <i class="bi bi-telephone me-2" />
        <span class="d-none d-sm-inline-block">09723 90 74 852</span>
      </a>
      <!-- {{-- E-Mail --}} -->
      <a class="text-decoration-none text-reset" href="mailto:info@skit-it.de">
        <i class="bi bi-envelope me-2"></i>
        <span class="d-none d-sm-inline-block">info@skit-it.de</span>
      </a>
      <!-- {{-- Instagram --}} -->
      <a class="text-decoration-none text-reset" target="_blank" href="https://www.instagram.com/skit_it_solutions/">
        <i class="bi bi-instagram me-2"></i>
        <span class="d-none d-sm-inline-block">skit-it.de</span>
      </a>
    </div>
  </div>
</div>
<nav class="navbar sticky-top navbar-expand-md navbar-light navbar-fixed-top bg-white shadow-sm">
  <div class="container">
    <a class="navbar-brand col" href="">
      <!-- {{-- {{ config('app.name', 'Laravel') }} --}} -->
      <img src="@/assets/images/skit-logo.png" alt="" class="logo-wrapper">
    </a>

    <span class="d-md-none col-auto" data-toggle="collapse" style="font-size:30px;cursor:pointer" @click="openNav"><i
        class="bi bi-list"></i></span>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Left Side Of Navbar -->
      <ul class="navbar-nav me-auto">

      </ul>

      <!-- Right Side Of Navbar -->
      <ul class="navbar-nav ml-auto">
        <!-- Authentication Links -->
        <li class="nav-item">
          <a class="nav-link" href="/#technic-service">Technikservice</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/#software">Softwarelösungen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/#contact">Kontakt</a>
        </li>
      </ul>
    </div>
  </div>
  <div id="myNav" class="overlay d-md-none">
    <div class="container">
      <a href="javascript:void(0)" class="closebtn ml-auto" @click="closeNav">&times;</a>
      <div @click="closeNav" class="overlay-content">
        <a href="/#technic-service">Technik-Service</a>
        <a href="/#software">Softwarelösungen</a>
        <a href="/#contact">Kontakt</a>
      </div>
    </div>
  </div>
</nav>
</template>
<script>
  export default {
    
    methods: {
      openNav() {
        document.getElementById("myNav").style.height = "100%";
      },

      closeNav() {
        document.getElementById("myNav").style.height = "0%";
      }
    }
  }
  </script>