import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import '@/style/layout.scss';
import 'bootstrap';
// Bootstrap Icons
import '/node_modules/bootstrap-icons/font/bootstrap-icons.css';

createApp(App).use(store).use(router).mount("#app");
